import { useState } from "react"
import './carousel.css';
import { useEffect } from "react";
import { useRef } from "react";

function Carousel(props) {
  // I am sorry to whoever has to read the abomination below
  useEffect(() => {
    let target = document.querySelector(".carousel-wrap")
    if (!target) {
      return;
    }
    let targetNumber = props.startNumber;
    if (targetNumber) {
      let targetEls = target.querySelectorAll(".number-" + targetNumber);
      let targetEl = targetEls[Math.floor(targetEls.length / 2)]
      if (!target) {
        return;
      }
      target.style.scrollBehavior = "unset";
      target.scrollTo({
        left: 0,
        top: targetEl.offsetTop - targetEl.parentElement.offsetHeight / 2,
      })
      setTimeout(() => {
        delete target.style.scrollBehavior
      }, 0)
    }
    else {
      target.scrollTo(0,
        target.scrollHeight / 2 - target.offsetHeight / 2 + 2*(target.offsetHeight * (Math.random() - 0.5))
      )
    }
    updateSelectedNumber();
  }, [])

  function updateSelectedNumber() {
    window.requestAnimationFrame(updateSelectedNumber)
    let newNumber = findSelectedNumber();
    if (newNumber) {
      props.numberChanged(newNumber)
    }
  }

  function findSelectedNumber() {
    let targetRect = document.querySelector(".carousel-mid")?.getBoundingClientRect();
    if (!targetRect) {
      return null;
    }
    let targetX = targetRect.top + (targetRect.bottom - targetRect.top) / 2;
    for (let e of document.querySelectorAll(".carousel-wrap *")) {
      let srcRect = e.getBoundingClientRect();
      if (targetX < srcRect.bottom && targetX > srcRect.top) {
        return e.innerHTML;
      }
    }
  }

  return (
    <div className="carousel-wrap-wrap">

      <div className="carousel-wrap">
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
        {props.children}
      </div>
      <div className="carousel-mid"></div>
    </div>
  );
}

export {Carousel}