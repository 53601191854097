let strings = {
  translations: {
    "id": "en",
    "newGame": "New Game",
    "loadGame": "Continue",
    "settings": "Settings",
    "loading": "Loading",
    "language": "Language",
    "setupCardInstructions": "Number of players. Setup card.",
    "dragColors": "Drag colors to the guild tiles.",
    "tutorial1": "Welcome to the Kutná Hora companion app. This app replaces the population and ore cards, and it can calculate players' income. This tutorial will give you an overview. Tap anywhere to continue.",
    "tutorial2": "In the bottom half, the app tracks commodity prices.",
    "tutorial3": "When ore production or population increases, let the app know by tapping the + button.",
    "tutorial4": "Players' income potential is tracked at the top of the screen.",
    "tutorial5": "When your production changes, tap this button to open a new window.",
    "tutorial6": "Use this to tell the app about your new building ...",
    "tutorial7": "... or this to tell the app about your new mine.",
    "tutorial8": "Production from the third St. Barbara tile can be recorded down here.",
    "tutorial9": "That's all you need to know! Good luck and have fun!",
    "cityBuilds": "The town builds a building. Discard the top tile from the stack.",
    "tutorial-skip": "Skip Tutorial",
    "settingsConfirm": "Confirm",
    "economyInformation": "Economy Info",
    "standard": "Standard",
    "open": "Open",
    "sounds": "Sounds",
    "undo": "Undo",
    "metallurgists-0": "Hammer Works",
    "metallurgists-1": "Furnace",
    "metallurgists-2": "Smelter",
    "metallurgists-3": "Ore Washworks",
    "miners-0": "Horse Mill",
    "miners-1": "Pump",
    "miners-2": "Airshaft",
    "miners-3": "Smithy",
    "scribes-0": "Assay Office",
    "scribes-1": "Ore Market",
    "scribes-2": "Mining Office",
    "scribes-3": "Courthouse",
    "builders-0": "Mason's",
    "builders-1": "Sawmill",
    "builders-2": "Limekiln",
    "builders-3": "Cathedral's Workshop",
    "grocers-0": "Bakery",
    "grocers-1": "Bread Market",
    "grocers-2": "Butcher's",
    "grocers-3": "Fish Market",
    "innkeepers-0": "Tavern",
    "innkeepers-1": "Bathhouse",
    "innkeepers-2": "Brewery",
    "innkeepers-3": "Wine Warehouse",
    "toastHouse": "Don't forget your house bonus!",
    "toastBarbara": "Don't forget to flip St. Barbara tile and increase taxes!",
    "toastMine": "Don't forget to place the free mine technology tile!",
    "public1": "A public building that raises pop. by 1.",
    "public2": "A public building that raises pop. by 2.",
    "econInfoPrice": "Current comodity value.",
    "econInfoGuilds": "Number of buildings built by the corresponding guild.",
    "econInfoCountdown": "Number of increases remaining before the price changes.",
    "econInfoPredict": "Possible values of the commodity after one of its buildings is built.",
    "openEconDisclaimer": "This mode will not affect the values of the commodities, but you will have more information about how are the values going to change. This mode is for players who like to have a bit more control of the game and want more complete infomation about the prices. However, it is not the originally intended way to play."
  }
}

export default strings;