let strings = {
  translations: {
      "id": "de",
      "newGame": "Neues Spiel",
      "loadGame": "Weiterspielen",
      "settings": "Einstellungen",
      "loading": "Lädt",
      "language": "Sprache",
      "setupCardInstructions": "Spieleranzahl und Aufbaukarte",
      "dragColors": "Ziehe die Spielerfarben zu den Gildenplättchen",
      "tutorial1": "Dies ist die Kutna Hora Begleit-App, welche die Bevölkerungs- und Erzkarten ersetzt sowie das Einkommen der Spieler berechnet. Tippt irgendwo auf den Hintergrund, um eine kurze Einführung zu erhalten.",
      "tutorial2": "In der unteren Hälfte werden die Preise der Waren angezeigt.",
      "tutorial3": "Wenn die Erzproduktion oder die Bevölkerung ansteigt, lasst es die App wissen indem ihr das + drückt.",
      "tutorial4": "Das potentielle Einkommen der Spieler wird am oberen Bildrand angezeigt.",
      "tutorial5": "",
      "tutorial6": "Hier kannst du der App deine neuen Gebäude…",
      "tutorial7": "… oder hier deine neuen Minen mitteilen.",
      "tutorial8": "Die Produktion durch das dritte Plättchen der Hl. Barbara kann hier unten vermerkt werden.",
      "tutorial9": "Das ist alles was ihr wissen müsst! Viel Spaß beim Spielen!",
      "cityBuilds": "Die Stadt baut ein Gebäude. Legt das oberste Plättchen vom Stapel ab.",
      "tutorial-skip": "Einführung beenden",
      "settingsConfirm": "Bestätigen",
      "economyInformation": "Wirtschaftsinformationen",
      "standard": "Standardmodus",
      "open": "Offener Modus",
      "sounds": "Ton",
      "undo": "Rückgängig",
      "metallurgists-0": "Hämmerei",
      "metallurgists-1": "Brennofen",
      "metallurgists-2": "Schmelzerei",
      "metallurgists-3": "Erzwäscherei",
      "miners-0": "Rossmühle",
      "miners-1": "Pumpenhaus",
      "miners-2": "Luftschacht",
      "miners-3": "Schmiede",
      "scribes-0": "Münzprüfanstalt",
      "scribes-1": "Erzmarkt",
      "scribes-2": "Bergbaubüro",
      "scribes-3": "Gericht",
      "builders-0": "Steinmetz",
      "builders-1": "Sägemühle",
      "builders-2": "Kalkofen",
      "builders-3": "Bauhütte",
      "grocers-0": "Bäckerei",
      "grocers-1": "Brotmarkt",
      "grocers-2": "Fleischerei",
      "grocers-3": "Fischmarkt",
      "innkeepers-0": "Taverne",
      "innkeepers-1": "Badehaus",
      "innkeepers-2": "Brauerei",
      "innkeepers-3": "Weinhändler",
      "toastHouse": "Vergiss deinen Hauseffekt nicht!",
      "toastBarbara": "Vergiss nicht das Plättchen der Hl. Barbara umzudrehen und die Steuern zu erhöhen!",
      "toastMine": "Vergiss nicht dein kostenloses Bergwerkplättchen zu platzieren!",
      "public1": "Ein öffentliches Gebäude, das die Bevölkerung um 1 erhöht.",
      "public2": "Ein öffentliches Gebäude, das die Bevölkerung um 2 erhöht.",
      "econInfoPrice": "Aktueller Warenwert.",
      "econInfoGuilds": "Anzahl der Gebäude, die von dieser Gilde erichtet worden sind.",
      "econInfoCountdown": "Anzahl von Erhöhungen ehe der Preis sich verändert.",
      "econInfoPredict": "Möglicher Warenwert, nachdem eines der zugehörigen Gildengebäude gebaut wurde.",
      "openEconDisclaimer": "Dieser Modus verändert nicht die Werte der Waren, gibt aber mehr Informationen darüber wie sich der Preis verändern wird. Er ist gedacht für Spieler die etwas mehr Kontrolle über das Spiel und genauere Informationen über die Preise haben wollen. Es ist allerdings nicht die usprünglich beabsichtigte Spielweise."
  }
}

export default strings;