let strings = {
  translations: {
	"id": "ko",
	"newGame": "새 게임",
	"loadGame": "계속하기",
	"settings": "설정",
	"loading": "불러오기",
	"language": "언어",
	"setupCardInstructions": "게임 인원수와 설정 카드.",
	"dragColors": "길드 타일로 색상을 끌어옵니다.",
	"tutorial1": "쿠트나 호라 컴패니언 앱에 오신 것을 환영합니다. 이 앱은 인구 카드와 광석 카드를 대신할 수 있으며, 플레이어들의 수입을 계산할 수 있습니다. 전체적인 내용을 파악하는 데 이 튜토리얼이 도움이 될 것입니다. 계속하려면 아무 곳이나 누르세요.",
	"tutorial2": "아래쪽 절반에서는 앱이 상품 가격을 추적합니다.",
	"tutorial3": "광석 생산이나 인구가 늘어날 때, + 버튼을 눌러 앱에 표시합니다.",
	"tutorial4": "플레이어의 잠재적인 수입은 화면 상단에서 추적할 수 있습니다.",
	"tutorial5": "자신의 생산량이 변동될 때, 이 버튼을 눌러 새 창을 엽니다.",
	"tutorial6": "이것을 사용하여 자신의 새 건물을 앱에 표시하거나,",
	"tutorial7": "자신의 새 광산을 앱에 표시할 수 있습니다.",
	"tutorial8": "세 번째 성 바바라 타일의 생산은 여기에 기록할 수 있습니다.",
	"tutorial9": "알아야 할 내용은 이것이 전부입니다. 행운을 빌고, 즐거운 시간 보내세요!",
	"cityBuilds": "시에서 건물 하나를 짓습니다. 해당 더미의 맨 위 타일을 버립니다.",
	"tutorial-skip": "튜토리얼 건너뛰기",
	"settingsConfirm": "결정",
	"economyInformation": "경제 정보",
	"standard": "기본",
	"open": "열기",
	"sounds": "소리",
	"undo": "되돌리기",
	"metallurgists-0": "망치 작업장",
	"metallurgists-1": "용광로",
	"metallurgists-2": "제련소",
	"metallurgists-3": "광석 세척장",
	"miners-0": "말 기중기",
	"miners-1": "펌프",
	"miners-2": "통풍 수직 갱도",
	"miners-3": "대장간",
	"scribes-0": "순도 분석소",
	"scribes-1": "광석 시장",
	"scribes-2": "광산 사무소",
	"scribes-3": "법원",
	"builders-0": "석공 조합",
	"builders-1": "제재소",
	"builders-2": "석회 가마",
	"builders-3": "대성당 작업장",
	"grocers-0": "빵집",
	"grocers-1": "빵 시장",
	"grocers-2": "정육점",
	"grocers-3": "생선 시장",
	"innkeepers-0": "선술집",
	"innkeepers-1": "목욕탕",
	"innkeepers-2": "양조장",
	"innkeepers-3": "와인 창고",
	"toastHouse": "주택 보너스를 기억하세요!",
	"toastBarbara": "성 바바라 타일을 뒤집고 세금 올리는 것을 잊지 마세요!",
	"toastMine": "잊지 말고 광산 기술 타일을 비용 없이 배치하세요!",
	"public1": "인구 1을 올리는 공공건물입니다.",
	"public2": "인구 2를 올리는 공공건물입니다.",
	"econInfoPrice": "현재 상품 가치",
	"econInfoGuilds": "해당 길드가 건설한 건물 수",
	"econInfoCountdown": "가격이 변하기 전에 남은 증가 횟수",
	"econInfoPredict": "건물 중 하나를 건설한 후에 변경될 상품 가치",
	"openEconDisclaimer": "이 모드는 상품 가치에는 영향을 미치지 않지만, 가치가 어떻게 변할지에 대해 더 많은 정보를 얻을 수 있습니다. 게임을 조금 더 적극적으로 통제하고, 더욱 정확한 가격 정보를 원하는 플레이어들은 이 모드를 사용하세요. 하지만, 이것은 원래 의도된 플레이 방식은 아닙니다."
}
}

export default strings