let setupCards = [
  [],
  [
    [],
    [],
    ["emi", "bsg"],
    ["geb", "ime", "sbm"],
    ["gem", "ims", "bie", "sgb"],
  ],
  [
    [],
    [],
    ["egm", "bis"],
    ["gme", "ebi", "bsm"],
    ["bme", "ism", "eig", "gbs"],
  ],
  [
    [],
    [],
    ["mbe", "gis"],
    ["sbg", "egi", "meb"],
    ["emg", "ibm", "bes", "gsi"],
  ],
  [
    [],
    [],
    ["smb", "ieg"],
    ["egm", "imb", "bes"],
    ["bem", "img", "egs", "sib"],
  ],
  [
    [],
    [],
    ["sgm", "bei"],
    ["seg", "eib", "bms"],
    ["emi", "gbm", "bse", "igs"],
  ],
  [
    [],
    [],
    ["ism", "ebg"],
    ["bgs", "ise", "emb"],
    ["iem", "gms", "egb", "sbi"],
  ],
  [
    [],
    [],
    ["gmb", "sie"],
    ["gie", "seb", "bmi"],
    ["smb", "igm", "esi", "beg"],
  ],
  [
    [],
    [],
    ["bim", "sge"],
    ["gbe", "bsi", "eim"],
    ["gsm", "mib", "sbe", "egi"],
  ],
  [
    [],
    [],
    ["gmi", "esb"],
    ["esg", "ibe", "bgm"],
    ["sim", "bmg", "esb", "ige"],
  ],
]

export default setupCards