let resValues = {
  wood: [
    [2, 1, 0, 0, 0],
    [3, 1, 0, 0, 0],
    [3, 2, 0, 0, 0],
    [3, 2, 1, 0, 0],
    [3, 2, 1, 0, 0],

    [3, 2, 1, 0, 0],
    [3, 2, 1, 0, 0],
    [3, 2, 1, 0, 0],
    [4, 2, 1, 0, 0],
    [4, 3, 1, 0, 0],

    [4, 3, 2, 0, 0],
    [4, 3, 2, 1, 0],
    [4, 3, 2, 1, 0],
    [4, 3, 2, 1, 0],
    [4, 3, 2, 1, 0],

    [5, 3, 2, 1, 0],
    [5, 3, 2, 1, 0],
    [6, 4, 2, 1, 0],
    [0, 4, 3, 1, 0],
    [0, 4, 3, 2, 1],

    [0, 4, 3, 2, 1],
    [0, 4, 3, 2, 1],
    [0, 4, 3, 2, 1],
    [0, 5, 3, 2, 1],
    [0, 5, 3, 2, 1],

    [0, 5, 4, 2, 1],
    [0, 6, 4, 3, 1],
    [0, 0, 4, 3, 2],
    [0, 0, 4, 3, 2],
    [0, 0, 4, 3, 2],

    [0, 0, 5, 3, 2],
    [0, 0, 5, 3, 2],
    [0, 0, 5, 3, 2],
    [0, 0, 5, 4, 2],
    [0, 0, 6, 4, 3],

    [0, 0, 0, 4, 3],
    [0, 0, 0, 4, 3],
    [0, 0, 0, 5, 3],
    [0, 0, 0, 5, 3],
    [0, 0, 0, 5, 4],

    [0, 0, 0, 5, 4],

    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
  ],
  food: [
    [3, 2, 0, 0, 0],
    [3, 2, 0, 0, 0],
    [4, 2, 1, 0, 0],
    [4, 2, 1, 0, 0],
    [4, 2, 1, 0, 0],

    [4, 3, 1, 0, 0],
    [4, 3, 2, 0, 0],
    [5, 3, 2, 0, 0],
    [5, 3, 2, 0, 0],
    [5, 3, 2, 1, 0],

    [5, 4, 2, 1, 0],
    [5, 4, 2, 1, 0],
    [6, 4, 3, 1, 0],
    [0, 4, 3, 1, 0],
    [0, 4, 3, 2, 0],

    [0, 5, 3, 2, 0],
    [0, 5, 3, 2, 0],
    [0, 5, 3, 2, 1],
    [0, 5, 4, 2, 1],
    [0, 5, 4, 2, 1],

    [0, 6, 4, 3, 1],
    [0, 0, 4, 3, 1],
    [0, 0, 4, 3, 2],
    [0, 0, 5, 3, 2],
    [0, 0, 5, 3, 2],

    [0, 0, 5, 3, 2],
    [0, 0, 5, 4, 2],
    [0, 0, 5, 4, 2],
    [0, 0, 6, 4, 3],
    [0, 0, 0, 4, 3],

    [0, 0, 0, 4, 3],
    [0, 0, 0, 5, 3],
    [0, 0, 0, 5, 3],
    [0, 0, 0, 5, 3],
    [0, 0, 0, 5, 4],

    [0, 0, 0, 5, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 0, 4],
    [0, 0, 0, 0, 4],

    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
    [0, 0, 0, 0, 5],
  ],
  beer: [
    [5, 3, 0, 0, 0],
    [6, 3, 2, 0, 0],
    [6, 4, 2, 0, 0],
    [6, 5, 2, 0, 0],
    [6, 5, 3, 0, 0],

    [6, 5, 3, 0, 0],
    [6, 5, 3, 0, 0],
    [6, 5, 3, 0, 0],
    [6, 5, 3, 0, 0],
    [7, 5, 3, 0, 0],

    [7, 6, 3, 2, 0],
    [7, 6, 4, 2, 0],
    [7, 6, 5, 2, 0],
    [7, 6, 5, 3, 0],
    [7, 6, 5, 3, 0],

    [8, 6, 5, 3, 0],
    [0, 6, 5, 3, 0],
    [0, 6, 5, 3, 0],
    [0, 7, 6, 3, 2],
    [0, 7, 6, 4, 2],

    [0, 7, 6, 5, 2],
    [0, 7, 6, 5, 3],
    [0, 7, 6, 5, 3],
    [0, 8, 6, 5, 3],
    [0, 0, 6, 5, 3],

    [0, 0, 6, 5, 3],
    [0, 0, 6, 5, 3],
    [0, 0, 7, 6, 4],
    [0, 0, 7, 6, 5],
    [0, 0, 7, 6, 5],

    [0, 0, 7, 6, 5],
    [0, 0, 8, 6, 5],
    [0, 0, 0, 6, 5],
    [0, 0, 0, 6, 5],
    [0, 0, 0, 6, 5],

    [0, 0, 0, 6, 5],
    [0, 0, 0, 7, 5],
    [0, 0, 0, 7, 5],
    [0, 0, 0, 7, 6],
    [0, 0, 0, 8, 6],

    [0, 0, 0, 0, 6],

    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
    [0, 0, 0, 0, 6],
  ],
  ore: [
    [3, 5, 0, 0, 0],
    [3, 4, 6, 0, 0],
    [2, 4, 6, 0, 0],
    [2, 4, 6, 0, 0],
    [2, 3, 5, 0, 0],

    [2, 3, 5, 0, 0],
    [2, 3, 5, 0, 0],
    [2, 3, 5, 0, 0],
    [2, 3, 4, 0, 0],
    [2, 3, 4, 6, 0],

    [1, 2, 4, 6, 0],
    [1, 2, 3, 5, 0],
    [1, 2, 3, 5, 0],
    [1, 2, 3, 5, 0],
    [1, 2, 3, 5, 0],

    [1, 2, 3, 4, 0],
    [1, 2, 3, 4, 6],
    [1, 1, 2, 4, 6],
    [1, 1, 2, 3, 5],
    [1, 1, 2, 3, 5],

    [1, 1, 2, 3, 5],
    [1, 1, 2, 3, 5],
    [1, 1, 2, 3, 4],
    [1, 1, 2, 3, 4],
    [1, 1, 1, 2, 4],

    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],

    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
    [1, 1, 1, 2, 3],
  ],
  silver: [
    [4, 2, 0, 0, 0],
    [4, 3, 1, 0, 0],
    [5, 3, 1, 0, 0],
    [5, 3, 1, 0, 0],
    [5, 4, 2, 0, 0],

    [5, 4, 2, 0, 0],
    [5, 4, 2, 0, 0],
    [5, 4, 2, 0, 0],
    [5, 4, 3, 0, 0],
    [5, 4, 3, 1, 0],

    [6, 5, 3, 1, 0],
    [6, 5, 4, 2, 0],
    [6, 5, 4, 2, 0],
    [6, 5, 4, 2, 0],
    [0, 5, 4, 2, 0],

    [0, 5, 4, 3, 0],
    [0, 5, 4, 3, 1],
    [0, 6, 5, 3, 1],
    [0, 6, 5, 4, 2],
    [0, 6, 5, 4, 2],

    [0, 6, 5, 4, 2],
    [0, 0, 5, 4, 2],
    [0, 0, 5, 4, 3],
    [0, 0, 5, 4, 3],
    [0, 0, 6, 5, 3],

    [0, 0, 6, 5, 4],
    [0, 0, 6, 5, 4],
    [0, 0, 6, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],

    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
    [0, 0, 0, 5, 4],
  ],
  paper: [
    [4, 2, 0, 0, 0],
    [4, 3, 1, 0, 0],
    [4, 3, 1, 0, 0],
    [4, 3, 2, 0, 0],
    [4, 3, 2, 0, 0],

    [5, 3, 2, 0, 0],
    [5, 4, 2, 0, 0],
    [5, 4, 2, 1, 0],
    [5, 4, 3, 1, 0],
    [5, 4, 3, 1, 0],

    [6, 4, 3, 1, 0],
    [6, 4, 3, 2, 0],
    [6, 5, 3, 2, 0],
    [6, 5, 4, 2, 0],
    [6, 5, 4, 2, 1],

    [6, 5, 4, 3, 1],
    [0, 5, 4, 3, 1],
    [0, 6, 4, 3, 1],
    [0, 6, 4, 3, 2],
    [0, 6, 5, 3, 2],

    [0, 6, 5, 4, 2],
    [0, 6, 5, 4, 2],
    [0, 6, 5, 4, 3],
    [0, 0, 5, 4, 3],
    [0, 0, 6, 4, 3],

    [0, 0, 6, 4, 3],
    [0, 0, 6, 5, 3],
    [0, 0, 6, 5, 4],
    [0, 0, 6, 5, 4],
    [0, 0, 0, 5, 4],

    [0, 0, 0, 6, 4],

    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
    [0, 0, 0, 6, 4],
  ]
}

let resBounds = {
  wood: [
    [0, 18],
    [3, 27],
    [11, 35],
    [19, Infinity],
    [Infinity, Infinity],
  ],
  food: [
    [0, 13],
    [2, 21],
    [9, 29],
    [17, 37],
    [Infinity, Infinity]
  ],
  beer: [
    [0, 16],
    [1, 24],
    [10, 32],
    [18, 40],
    [Infinity, Infinity]
  ],
  ore: [
    [0, Infinity],
    [0, Infinity],
    [0, Infinity],
    [0, Infinity],
    [0, Infinity],
  ],
  silver: [
    [0, 14],
    [1, 21],
    [9, 28],
    [16, Infinity],
    [Infinity, Infinity]
  ],
  paper: [
    [0, 16],
    [1, 23],
    [7, 29],
    [14, Infinity],
    [Infinity, Infinity]
  ]
}

export { resValues, resBounds };