import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.js"
import ko from "./translations/ko.js"
import cs from "./translations/cs.js"
import es from "./translations/es.js"
import ja from "./translations/ja.js"
import de from "./translations/de.js"
import fr from "./translations/fr.js"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en, cs, es, ja, de, fr, ko
    },
    fallbackLng: "en",
    debug: true,

    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    },
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
  });

export default i18n;