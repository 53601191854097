let strings = {
  translations: {
    "id": "cs",
    "newGame": "Nová hra",
    "loadGame": "Pokračovat",
    "settings": "Nastavení",
    "loading": "Načítám",
    "language": "Jazyk",
    "setupCardInstructions": "Počet hráčů a příprava cechů.",
    "dragColors": "Přetáhni barvy k dílkům cechů.",
    "tutorial1": "Vítej v appce pro Kutnou Horu. Appka nahrazuje karty obyvatel a rudy, a také produkci hráčů. V tomto krátkém tutoriálu se dozvíš, jak appku používat. Pokračuj tapnutím kamkoli.",
    "tutorial2": "Ve spodní půlce obrazovky appka zobrazuje ceny surovin.",
    "tutorial3": "Když se změní množství obyvatel nebo těžby rudy, zadej to pomocí tlačítka +",
    "tutorial4": "V horní části obrazovky je zobrazen potenciální příjem hráčů.",
    "tutorial5": "Když zvyšuješ svou produkci, tapnutím na toto tlačítko otevřeš menu stavby",
    "tutorial6": "Zde zadej novou budovu, kterou stavíš ...",
    "tutorial7": "... a zde zadej nový důl.",
    "tutorial8": "Produkce z efektu sv. Barbory zadej zde.",
    "tutorial9": "To je vše! Hodně štěstí a příjemnou zábavu! 😊",
    "cityBuilds": "Město staví budovu. Zahoď horní budovu z hromádky.",
    "tutorial-skip": "Přeskočit tutoriál",
    "settingsConfirm": "Potvrdit",
    "economyInformation": "Ekonomika",
    "standard": "Jako v deskovce",
    "open": "Předvídatelná",
    "sounds": "Zvuky",
    "undo": "Vrátit akci",
    "metallurgists-0": "Buchar",
    "metallurgists-1": "Hutní pec",
    "metallurgists-2": "Slévárna",
    "metallurgists-3": "Prádlo rudy",
    "miners-0": "Trejv",
    "miners-1": "Pumpa",
    "miners-2": "Luftšachta",
    "miners-3": "Kovárna",
    "scribes-0": "Prubířství",
    "scribes-1": "Rudný trh",
    "scribes-2": "Horní úřad",
    "scribes-3": "Soud",
    "builders-0": "Kamenictví",
    "builders-1": "Pila",
    "builders-2": "Vápenka",
    "builders-3": "Stavební huť",
    "grocers-0": "Pekárna",
    "grocers-1": "Chlebové lavice",
    "grocers-2": "Řeznictví",
    "grocers-3": "Rybí trh",
    "innkeepers-0": "Krčma",
    "innkeepers-1": "Lázně",
    "innkeepers-2": "Pivovar",
    "innkeepers-3": "Sklad vína",
    "toastHouse": "Nezapomeň na efekt za umístění domečku!",
    "toastBarbara": "Nezapomeň otočit další dílek sv. Barbory a zvýšit daně!",
    "toastMine": "Nezapomeň zdarma umístit dílek důlní technologie!",
    "public1": "Veřejná budova zvyšující počet obyvatel o 1.",
    "public2": "Veřejná budova zvyšující počet obyvatel o 2.",
    "econInfoPrice": "Aktuální hodnota komodity.",
    "econInfoGuilds": "Množství postavených budov tohoto cechu.",
    "econInfoCountdown": "Kolikrát se musí zvýšit počet obyvatel / množství těžené rudy, aby se změnila cena.",
    "econInfoPredict": "Možné hodnoty komodity po tom, co se postaví budova daného cechu.",
    "openEconDisclaimer": "Tento mód používá karty obyvatel a rudy z deskové hry, ale poskytuje více informací o tom, kdy se změní ceny komodit. Je určený pro hráče, kteří mají rádi nad hrou větší kontrolu a chtějí mít lepší přehled o možných cenách. Není to ale původně zamýšlený způsob hraní."
  }
}

export default strings;