let strings = {
  translations: {
    "id": "ja",
    "newGame": "新たなゲームを始める",
    "loadGame": "つづきをプレイする",
    "settings": "設定",
    "loading": "ローディング中",
    "language": "言語",
    "setupCardInstructions": "プレイ人数\nギルド準備カード",
    "dragColors": "プレイヤーカラーを\n決めてください。",
    "tutorial1": "『クトナー・ホラ コンパニオンアプリ』へようこそ。このアプリは人口カードと鉱石カードの代わりとなり、プレイヤーの収入を計算することができます。このチュートリアルでは概要を説明します。続けるにはどこかをタップしてください。",
    "tutorial2": "下半分には商品の価格が\n表示されます。",
    "tutorial3": "鉱石の算出量や\n人口が増えたら、\n＋ボタンをタップして\nアプリに入力します。",
    "tutorial4": "各プレイヤーの収入は\n画面上に表示されます。",
    "tutorial5": "商品の生産量が増えたら、\nここをタップして新たな\nウィンドウを開きます。",
    "tutorial6": "ここで建設した建物の\n入力をするか、",
    "tutorial7": "新たな鉱山の\n入力をします。",
    "tutorial8": "聖バルボラタイルの\n3枚目のタイルの生産は\nここで入力します。",
    "tutorial9": "操作方法はこれだけです。\nプレイを楽しんでください！",
    "cityBuilds": "町が建物を建設します。\nこの山の一番上のタイルを\n捨て札にします。",
    "tutorial-skip": "チュートリアルをとばす",
    "settingsConfirm": "確認",
    "economyInformation": "経済情報",
    "standard": "通常モード",
    "open": "公開モード",
    "sounds": "サウンド",
    "undo": "取り消し",
    "metallurgists-0": "砕石所",
    "metallurgists-1": "溶鉱炉",
    "metallurgists-2": "精錬所",
    "metallurgists-3": "鉱石洗浄所",
    "miners-0": "馬力加工所",
    "miners-1": "揚水機",
    "miners-2": "通風縦坑",
    "miners-3": "鍛冶場",
    "scribes-0": "鉱石分析所",
    "scribes-1": "鉱石市場",
    "scribes-2": "採掘事務所",
    "scribes-3": "裁判所",
    "builders-0": "石工",
    "builders-1": "製材所",
    "builders-2": "石灰窯",
    "builders-3": "大聖堂の作業場",
    "grocers-0": "製パン所",
    "grocers-1": "パン市場",
    "grocers-2": "肉屋",
    "grocers-3": "魚市場",
    "innkeepers-0": "酒場",
    "innkeepers-1": "公衆浴場",
    "innkeepers-2": "醸造所",
    "innkeepers-3": "ワイン倉庫",
    "toastHouse": "家駒配置による効果を\nお忘れなく！",
    "toastBarbara": "現在の聖バルボラタイルを裏返し、\n税金を増やしてください。",
    "toastMine": "一番上の採掘技術タイルを取り、\n無料でプレイしてください。",
    "public1": "人口が1増える\n公共建物",
    "public2": "人口が2増える\n公共建物",
    "econInfoPrice": "現在の商品価格",
    "econInfoGuilds": "対応するギルドが\n建設した建物数",
    "econInfoCountdown": "価格が変更されるまでの\n残りカード枚数",
    "econInfoPredict": "対応するギルド建物が\n建設された後に\n変化しうる商品価格",
    "openEconDisclaimer": "公開モードは商品の価値には影響しませんが、価値がどのように変化するかについて、より多くの情報を得ることができます。このモードは、ゲームをより操作可能としたいプレイヤーや、価格変動に関するより完全な情報が欲しいプレイヤーのためのものとなります。ただし、本来意図されたプレイ方法ではありません。"
  }
}

export default strings;