/* eslint-disable no-unreachable */
import logo from './logo.svg';
import './App.css';

import './tutorial.css';
import React, { useState, useEffect, useRef } from 'react';
import buildingNames from './buildingNames';
import nosleep from 'nosleep.js';
import { Html5QrcodeScanner } from "html5-qrcode";
import { resBounds, resValues } from './values';
import { Trans, useTranslation } from 'react-i18next';
import { Settings } from './setttings';
import { ReactSortable } from 'react-sortablejs';
import setupCards from './setupCards';
import Draggable from 'react-draggable';
import { Carousel } from './carousel';
import { NavLink, Routes, Route, useNavigate, BrowserRouter, useLocation } from 'react-router-dom';
import i18n from './i18n';
import ReactGA from 'react-ga4';
const TRACKING_ID = "G-V3LG81R94G";
ReactGA.initialize(TRACKING_ID);
const audio = {
  tap: new Audio("/sounds/tap.wav"),
  slide: new Audio("/sounds/slide.wav"),
  slide2: new Audio("/sounds/slide2.wav"),
  tapSelect: new Audio("/sounds/tapselect.wav"),
  popup: new Audio("/sounds/popup.wav")
}
for (let i in audio) {
  let newVolume = {
    slide2: 0.3,
    tapSelect: 1,
    tap: 0.6,
    popup: 0.8
  }[i]
  audio[i].volume = newVolume ?? 0.7;

}

function playSound(name) {
  if (localStorage.getItem("soundOn") == "true") {
    audio[name]?.play();
  }
}

const METALURG = "metallurgists";
const BUILDER = "builders";
const GROCER = "grocers";
const MINER = "miners";
const SCRIBES = "scribes";
const INNKEEP = "innkeepers";

const PEOPLE = "people";
const VER = "2023-07-19";

var noSleep = new nosleep();

const guildCards = [
  [METALURG, BUILDER, GROCER],
  [MINER, SCRIBES, INNKEEP],

  [METALURG, BUILDER, GROCER],
  [BUILDER, MINER, SCRIBES],
  [MINER, METALURG, INNKEEP],

  [MINER, BUILDER, GROCER],
  [METALURG, GROCER, SCRIBES],
  [MINER, SCRIBES, INNKEEP],
  [METALURG, INNKEEP, BUILDER],
];

const GUILD_LIST = [METALURG, BUILDER, GROCER, MINER, SCRIBES, INNKEEP];

const resourceColors = {
  wood: "#bf915d",
  beer: "#7e593a",
  food: "#c08270",
  ore: "#b6a899",
  silver: "#8a9eb2",
  paper: "#e9cfaa"
}

const guildToRes = {
  metallurgists: "silver",
  builders: "wood",
  innkeepers: "beer",
  grocers: "food",
  scribes: "paper",
  miners: "ore"
}
const resToGuild = {
  silver: "metallurgists",
  wood: "builders",
  beer: "innkeepers",
  food: "grocers",
  paper: "scribes",
  ore: "metallurgists",
}

const resTracks = {
  ore: "miners",
  wood: "people",
  silver: "miners",
  food: "people",
  paper: "miners",
  beer: "people",
}

/*const guildImages = [
  require("./assets/guilds/guild1.png"),
  require("./assets/guilds/guild2.png"),
  require("./assets/guilds/guild3.png"),
  require("./assets/guilds/guild4.png"),
  require("./assets/guilds/guild5.png"),
  require("./assets/guilds/guild6.png"),
  require("./assets/guilds/guild7.png"),
  require("./assets/guilds/guild8.png"),
  require("./assets/guilds/guild9.png"),
]*/

const buildingList = {

  builders: [
    { moves: [BUILDER], prodAmt: 5, prodRes: "wood", num: 0, cat: "builders" },
    { moves: [BUILDER, PEOPLE], prodAmt: 3, prodRes: "wood", num: 1, cat: "builders" },
    { moves: [BUILDER], prodAmt: 4, prodRes: "wood", num: 2, cat: "builders" },
    { moves: [BUILDER, PEOPLE], prodAmt: 7, prodRes: "wood", num: 3, cat: "builders" },
  ],
  grocers: [
    { moves: [GROCER, PEOPLE, PEOPLE], prodAmt: 4, prodRes: "food", num: 0, cat: "grocers" },
    { moves: [GROCER, PEOPLE, PEOPLE], prodAmt: 4, prodRes: "food", num: 1, cat: "grocers" },
    { moves: [GROCER, PEOPLE], prodAmt: 5, prodRes: "food", num: 2, cat: "grocers" },
    { moves: [GROCER, PEOPLE], prodAmt: 6, prodRes: "food", num: 3, cat: "grocers" },
  ],
  innkeepers: [
    { moves: [INNKEEP, PEOPLE], prodAmt: 2, prodRes: "beer", num: 0, cat: "innkeepers" },
    { moves: [INNKEEP, PEOPLE], prodAmt: 2, prodRes: "beer", num: 1, cat: "innkeepers" },
    { moves: [INNKEEP, PEOPLE], prodAmt: 3, prodRes: "beer", num: 2, cat: "innkeepers" },
    { moves: [INNKEEP, PEOPLE, PEOPLE], prodAmt: 5, prodRes: "beer", num: 3, cat: "innkeepers" },
  ],
  metallurgists: [
    { moves: [METALURG], prodAmt: 3, prodRes: "silver", num: 0, cat: "metallurgists" },
    { moves: [METALURG], prodAmt: 3, prodRes: "silver", num: 1, cat: "metallurgists" },
    { moves: [METALURG], prodAmt: 4, prodRes: "silver", num: 2, cat: "metallurgists" },
    { moves: [METALURG], prodAmt: 7, prodRes: "silver", num: 3, cat: "metallurgists" },
  ],
  miners: [
    { moves: [MINER, PEOPLE], prodAmt: 1, prodRes: "ore", num: 0, cat: "miners" },
    { moves: [MINER, MINER, MINER], prodAmt: 3, prodRes: "ore", num: 1, cat: "miners" },
    { moves: [MINER, MINER], prodAmt: 2, prodRes: "ore", num: 2, cat: "miners" },
    { moves: [MINER, MINER, MINER], prodAmt: 3, prodRes: "ore", num: 3, cat: "miners" },
  ],
  scribes: [
    { moves: [SCRIBES], prodAmt: 3, prodRes: "paper", num: 0, cat: "scribes" },
    { moves: [SCRIBES, PEOPLE], prodAmt: 3, prodRes: "paper", num: 1, cat: "scribes" },
    { moves: [SCRIBES, PEOPLE], prodAmt: 3, prodRes: "paper", num: 2, cat: "scribes" },
    { moves: [SCRIBES, PEOPLE], prodAmt: 6, prodRes: "paper", num: 3, cat: "scribes" },
  ],
  public: [
    { moves: [PEOPLE], num: 1, cat: "public" },
    { moves: [PEOPLE, PEOPLE], num: 2, cat: "public" },
  ],
  mine: [
    { moves: [PEOPLE], prodAmt: 0, prodRes: "ore", num: 2, level: 1, cat: "mine" },
    { moves: [PEOPLE, PEOPLE], prodAmt: 2, prodRes: "ore", num: 7, level: 1, cat: "mine" },
    { moves: [MINER], prodAmt: 1, prodRes: "ore", num: 1, level: 1, cat: "mine" },
    { moves: [PEOPLE, MINER], prodAmt: 1, prodRes: "ore", num: 4, level: 1, cat: "mine" },
    { moves: [MINER, MINER], prodAmt: 2, prodRes: "ore", num: 3, level: 1, cat: "mine" },
    { moves: [PEOPLE, MINER, MINER], prodAmt: 2, prodRes: "ore", num: 6, level: 1, cat: "mine" },
    { moves: [MINER, MINER, MINER], prodAmt: 3, prodRes: "ore", num: 5, level: 1, cat: "mine" },
  ],
  barbara: [
    { moves: [], prodAmt: 2, prodRes: "ore", cat: "barbara", num: 'ore' },
    { moves: [], prodAmt: 2, prodRes: "food", cat: "barbara", num: 'food' },
    { moves: [], prodAmt: 2, prodRes: "silver", cat: "barbara", num: 'silver' },
    { moves: [], prodAmt: 2, prodRes: "beer", cat: "barbara", num: 'beer' },
    { moves: [], prodAmt: 2, prodRes: "wood", cat: "barbara", num: 'wood' },
    { moves: [], prodAmt: 2, prodRes: "paper", cat: "barbara", num: 'paper' },
  ]
}

const guilds = [
  [],
  [],
  [0, 1],
  [2, 3, 4],
  [5, 6, 7, 8]
]

const guildSortableList = [
  {id: "scribes", name: "scribes"},
  {id: "builders", name: "builders"},
  {id: "metallurgists", name: "metallurgists"},
  {id: "miners", name: "miners"},
  {id: "innkeepers", name: "innkeepers"},
  {id: "grocers", name: "grocers"},
]

function SetupPlayer(props) {
  return <></>
  let p = props.playerInfo;
  return <div>
    <span>{p.playerName}</span>
    <span className="guild-list">{p.selectedGuildList}</span>
  </div>
}

function TutorialOverlay(props) {
  let [screenNum, setScreenNum] = useState(0);
  let {t} = useTranslation();
  let screens = [
    {
      rect: {
        left: "50%",
        top: "0",
        width: "10dvh",
        height: "0dvh",
      },
      text: {
        text: t("tutorial1"),
        coords: {
          left: "5%",
          top: "8dvh",
          width: "90%"
        }
      }
    },
    {
      rect: ".resource-wrap",
      text: {
        text: t("tutorial2"),
        coords: {
          left: "5%",
          top: "8dvh",
          width: "90%"
        }
      }
    },
    {
      rect: ".people-miners-wrap",
      text: {
        text: t("tutorial3"),
        coords: {
          left: "5%",
          bottom: "20dvh",
          width: "90%"
        }
      }
    },
    {
      rect: ".player-info",
      text: {
        text: t("tutorial4"),
        coords: {
          left: "5%",
          top: "16dvh",
          width: "90%"
        }
      }
    },
    {
      rect: ".player-build-button",
      text: {
        text: t("tutorial5"),
        coords: {
          left: "5%",
          top: "16dvh",
          width: "90%"
        }
      }
    },
    {
      rect: {
        top: "5dvh",
        width: "90%",
        height: "45dvh",
        left: "5%"
      },
      text: {
        text: t("tutorial6"),
        coords: {
          left: "5%",
          bottom: "16dvh",
          width: "90%"
        }
      },
      action: () => {
        props.setPlayerBuilding(document.querySelector(".player-info-wrap").getAttribute("playerColor"))
      }
    },
    {
      rect: ".mining-tab",
      text: {
        text: t("tutorial7"),
        coords: {
          left: "5%",
          bottom: "16dvh",
          width: "90%"
        }
      },
    },
    {
      rect: ".barbara-prod",
      text: {
        text: t("tutorial8"),

        coords: {
          left: "5%",
          bottom: "40dvh",
          width: "90%"
        }
      },
    },
    {
      rect: {
        left: 0,
        bottom: 0,
        width: "100%",
        height: 0
      },
      text: {
        text: t("tutorial9"),
        coords: {
          left: "5%",
          bottom: "30dvh",
          width: "90%"
        }
      },
    },
  ]

  useEffect(() => {
    let currScreen = screens[screenNum];
    if (currScreen.action) {
      currScreen.action();
    }
  }, [screenNum])
  let currScreen = screens[screenNum];
  function endTutorial() {
    localStorage.setItem("kh-tutorial-complete", "complete")
    props.setTutorialActive(false);
    props.setPlayerBuilding(undefined);
    return;
  }
  let rectStyle;
  if (typeof(currScreen.rect) == "string") {
    let rect = document.querySelector(currScreen.rect);
    if (!rect) {
      rectStyle = {}
    }
    else {
      rectStyle = {
        left: rect.offsetLeft + "px",
        top: rect.offsetTop + "px",
        height: rect.offsetHeight + "px",
        width: rect.offsetWidth + "px"
      }
    }
  }
  else {
    rectStyle = currScreen.rect;
  }
  let seeThrough = <div className='tutorial-seethrough' style={rectStyle} ></div>
  let nextButton = <button className='tutorial-continue' >
    {t("tutorial-continue")}
  </button>
  let skipButton = <button className='tutorial-skip' onClick={() => {
    endTutorial();
    playSound("tap")
  }}>
    {t("tutorial-skip")}
  </button>
  let tutorialText = <div className='tutorial-text' style={currScreen.text.coords}>{currScreen.text.text}</div>
  return <><div className='tutorial-overlay' onClick={() => {
    if (screens.length - 1 == screenNum) {
      endTutorial();
    }
    setScreenNum(s => s+1);
    let whiteRect = document.querySelector(".tutorial-seethrough");
    if (whiteRect) {
      whiteRect.animate(
        [{opacity: 1}, {opacity: 0.2}, {opacity: 0.2}, {opacity: 1}],
        {duration: 500, iterations: 1})
    }
  }}>
    {seeThrough}
  </div>
    {skipButton}
    {tutorialText}
  </>
}

function GuildSelect(props) {
  let [selectedGuildList, setSelectedGuildList] = useState([]);
  let [selectedSetupGuilds, setSelectedSetupGuilds] = useState(-1);
  let [selectedGuilds, setSelectedGuilds] = useState({});
  let [selectedName, setSelectedName] = useState({});
  let [colorPositions, setColorPositions] = useState({});
  let [guildHovers, setGuildHovers] = useState([]);
  const navigate = useNavigate();

  const {t, i18n} = useTranslation();
  let namePool = [
    "Havel",
    "Lionardo",
    "Gunther",
    "Bartolommeo",
    "Baldewin",
    "Niedhart",
    "Hellenbrecht",
    "Smil",
    "Jeschek",
    "Markward",
    "Eckehard",
    "Rys",
    "Gherardino",
    "Dietmar",
    "Wenceslaus",
    "Klösel",
    "Apecz",
    "Jan",
    "Vincencius",
    "Jeronymus",
    "Nicze",
    "Pawel",
    "Leutold",
    "Perchta",
    "Gertrud",
    "Libusch",
    "Kunegunde",
  ]
  let names = [];
  for (let i = 0; i < props.playerAmt; ++i) {
    names.push(randomName());
  }
  let [defaultNames, setDefaultNames] = useState(names)
  function randomName() {
    let name;
    do {
      name = namePool[Math.floor(Math.random() * namePool.length)];
    } while (props.players?.find(p => p.playerName == name) || names.find(p => p == name));
    console.log(props.players)
    return name;
  }

  let guildButtons = [];
  let buttons = [];
  let selectedButtons = [];
  let setupGuilds = setupCards[props.selectedSetupCard][props.playerAmt];
  console.log(setupCards, props.selectedSetupCard, setupGuilds)
  if (!setupGuilds) {
    return <div>error</div>
  }
  function expandName(n) {
    return {
      "b": "builders",
      "e": "metallurgists",
      "m": "miners",
      "g": "grocers",
      "s": "scribes",
      "i": "innkeepers"
    }[n]
  }
  let setupNum = 0;
  let i = 0;
  for (let guilds of setupGuilds) {
    let guildIcons = [];
    for (let guildName of guilds) {
      let expandedName = expandName(guildName);
      guildIcons.push(<div
        className={"guild-setup-icon guild-setup-icon-" + expandedName}
        guildName={expandedName}
      >{}</div>);
    }
    let _setupNum = setupNum;
    let disabled = props.selectedSetupNums.includes(setupNum)
    let nameInput = <div className={'name-input-wrap ' + (!guildHovers[_setupNum] || guildHovers[_setupNum] == "blank" ? "hidden" : "")}>
      <input
        className={"name-input "}
        type="text"
        name="playerName"
        defaultValue={defaultNames[i++]}>
      </input>
      <div className='name-edit-icon'></div>
      </div>
    guildButtons.push(<div className={'guild-select-wrap'} guildNum={_setupNum}><div
      className={'setup-card-button' + (" hover-" + guildHovers[_setupNum])}
      guildNum={_setupNum}
      disabled={disabled}

      >
        <div className='blank-player'></div>
        <div className='setup-guild-wrap'>{guildIcons}</div></div>
        {nameInput}
    </div>)
    ++setupNum;
  }
  let colorButtons = [];
  function getGuildOver(sourceRect) {
    for (let guild of document.querySelectorAll(".setup-card-button")) {
      console.log(selectedGuilds)
      if (Object.values(selectedGuilds).includes(guild.getAttribute("guildnum"))) {
        continue;
      }
      let targetRect = guild.getBoundingClientRect();
      let margin = 20;
      if (
        targetRect.left - sourceRect.left < margin &&
        - targetRect.right + sourceRect.right < margin * 3 &&
        targetRect.top - sourceRect.top < margin &&
        targetRect.bottom - sourceRect.bottom > -margin
      ) {
        return guild;
      }
    }
  }
  function colorSelect(color, guilds) {
    setSelectedGuilds(oldColors => {
      let newColors = Object.assign({}, oldColors);
      if (guilds === -1) {
        delete newColors[color];
      }
      else {
        newColors[color] = guilds;
      }
      return newColors;
    });
  }
  let bases = []
  for (let color of ["green", "orange", "purple", "blue"]) {
    colorButtons.push(<Draggable
      position={colorPositions[color]}
      onStop={function(evt) {
        console.log(evt);
        setColorPositions(c => {
          let newPositions = JSON.parse(JSON.stringify(c));
          let targetPosition = {x: 0, y: 0}
          let rect = evt.target.getBoundingClientRect();
          let targetGuild = getGuildOver(rect);
          let leftOffset = 0.26;
          let topOffset = 0.45;
          if (targetGuild) {
            targetPosition = {
              x: targetGuild.offsetLeft - evt.target.offsetLeft + targetGuild.offsetWidth * leftOffset - evt.target.offsetWidth * 0.5,
              y: targetGuild.offsetTop - evt.target.offsetTop + targetGuild.offsetHeight * topOffset - evt.target.offsetHeight * 0.5,
            }
            colorSelect(color, targetGuild.getAttribute("guildnum"))
            playSound('tapSelect')
          }
          else {
            colorSelect(color, -1)
          }
          newPositions[color] = targetPosition
          return newPositions
        })
      }}
      onDrag={function(evt) {
        let rect = evt.target.getBoundingClientRect();
        let targetGuild = getGuildOver(rect);
        if (targetGuild) {
          setGuildHovers(oldHovers => {
            let newHovers = oldHovers?.slice() ?? [];
            newHovers[targetGuild.getAttribute("guildNum")] = color;
            return newHovers;
          })
        }
        else {
          setGuildHovers(oldHovers => {
            let newHovers = oldHovers?.slice() ?? [];
            for (let h in newHovers) {
              if (newHovers[h] === color) {
                newHovers[h] = "blank"
              }
            }
            return newHovers;
          })
        }
      }}
      ><div
      className={"color-button " + color}
    >
    </div></Draggable>
)
    bases.push(<div className='marker-base'></div>)
  }
  let submitButton = null;
  if (Object.values(selectedGuilds)?.filter(a => a).length == props.playerAmt) {
    submitButton = <button
    disabled={Object.keys(selectedGuilds)?.length < props.playerAmt}
    className={"submit-button"}
    onClick={
      (evt) => {
        playSound("tap");
        props.setPlayers(() => {
          let result = [];
          console.log(setupGuilds)
          for (let color in selectedGuilds) {
            let nameInput = document.querySelector(
              ".guild-select-wrap[guildnum='" + selectedGuilds[color] + "'] input[name='playerName']"
            )
            let playerName = nameInput.value;
            if (playerName.length == 0) {
              playerName = nameInput.placeholder;
            }
            result.push({
              playerName,
              color,
              selectedGuildList: [...setupGuilds[selectedGuilds[color]]].map(n => expandName(n)),
              selectedSetupNum: selectedSetupGuilds,
              buildings: [],
              extraProduction: {}
            })
          }
          props.startGame(result);
          return result;
        })
      }
    }
    name="submit"></button>


  }
  return <>
  <SetupTopBar></SetupTopBar>
      <div className='setup-instructions'>{t("dragColors")}</div>
      <div className='color-buttons-wrap'>
      {colorButtons}
      </div>
      <div className='bases-wrap'>
      {bases}
      </div>
      <div className='guild-buttons-wrap'>
      {guildButtons}
      </div>

      {submitButton}
  </>
}

function saveGame(saving) {
  let oldSave = {}
  if (localStorage["kut-saved-game"]) {
    oldSave = JSON.parse(localStorage["kut-saved-game"]);
  }
  let newSave = Object.assign(oldSave, saving);
  if (newSave.players) {
    newSave.players = Object.values(newSave.players);
  }
  localStorage.setItem("kut-saved-game", JSON.stringify(newSave));
}

function GameTab(props) {
  let players = [];
  let [playerBuilding, setPlayerBuilding] = useState();
  let [toastNotifs, setToastNotifs] = useState([]);
  let [tutorialActive, setTutorialActive] = useState(!localStorage.getItem("kh-tutorial-complete"));
  const {t} = useTranslation()
  let stateHistory = useRef([]);
  let prevState = useRef();

  if (!props.levels) {
    return <div>loading</div>
  }

  let resGuilds = {
    ore: "metallurgists",
    wood: "builders",
    silver: "metallurgists",
    food: "grocers",
    paper: "scribes",
    beer: "innkeepers",
  };
  function getResourceCosts() {
    if (!props.levels) {
      return {};
    }
    let levels = props.levels;

    let bounds = {};
    let coinValues = {};
    let values = {};
    for (let resName in resGuilds) {
      let trackLevel = levels[resTracks[resName]];
      let guildLevel = levels[resGuilds[resName]];
      console.log(resName, guildLevel, trackLevel);
      coinValues[resName] = resValues[resName][trackLevel][guildLevel];
      bounds[resName] = resBounds[resName][guildLevel];
    }
    console.log({ bounds, levels });
    values.miners = { number: levels.miners };
    values.people = { number: levels.people };
    for (let resName in resGuilds) {
      let color = "normal";
      let trackValue = levels[resTracks[resName]];
      if (trackValue < bounds[resName][0]) {
        color = "orange";
      }
      if (trackValue >= bounds[resName][1]) {
        color = "red";
      }
      values[resName] = {
        number: coinValues[resName],
        color
      }
    }

    return values;
  };
  let values = getResourceCosts();
  let cityBuilds = false;
  let cityOverlay = null;
  for (let resName in values) {
    if (values[resName].color === "red") {
      cityBuilds = true;
      cityOverlay = <div className='city-builds-overlay'>{t("cityBuilds")}</div>
    }
  }

  function build(player, building) {
    console.log("building", player, building);
    let newLevels;
    let timeout = 100;
    for (let move of building.moves) {
      setTimeout(() => {
        adjustLevel(move, 1);
      }, timeout)
      timeout += 1000;
    }
    setTimeout(() => {
      setToastNotifs(notifs => {
        let message;
        let icons;
        if (["grocers", "innkeepers"].includes(building.cat)) {
          message = t("toastBarbara")
          icons = ["flip-barbara"]
        }
        if (["scribes", "builders", "metallurgists", "miners"].includes(building.cat)) {
          message = t("toastHouse")
          icons = ["free-rights", "pelican", "reputation"]
        }
        if (building.cat == "miners") {
          message = t("toastMine")
          icons = ["free-mine"]
        }
        if (message && notifs?.concat) {
          return notifs.concat([{message, icons}])
        }
        return notifs;
      })

    }, timeout)
    props.setPlayers(prevPlayers => {
      saveHistory({players: [...prevPlayers], levels: props.levels});
      let players = JSON.parse(JSON.stringify(prevPlayers));
      let p = players.find(p => p.color == player.color);
      if (building.cat !== "public") {
        p.buildings.push(building);
      }
      saveGame({players})
      return players;
    });
    setPlayerBuilding(undefined);

  }
  function undo() {
    if (props.stateHistory.length) {
      let targetState = props.stateHistory[props.stateHistory.length - 1];
      props.setStateHistory(history => {
        let newHistory = history.slice(0, history.length - 1);
        saveGame({stateHistory: newHistory})
        return newHistory;
      });
      props.setPlayers(targetState.players);
      props.setLevels(targetState.levels);
      saveGame(targetState);
    }
  }

  for (let p of props.players) {
    players.push(<GamePlayer
      playerInfo={p}
      builds={playerBuilding == p.color}
      key={"gameplayer-" + p.color}
      setPlayerBuilding={setPlayerBuilding}
      values={values}
    ></GamePlayer>)
  }
  let toasts = [];
  for (let t in toastNotifs) {
    toasts.push(<ToastNotif
      toastInfo={toastNotifs[t]}
      key={"toast" + t}
    ></ToastNotif>)
  }
  let buildTab = <></>;
  let builtBuildings = [];
  for (let p of props.players) {
    builtBuildings.push(...p.buildings);
  }
  console.log({builtBuildings})
  let buildingEntity = playerBuilding;
  let forceType = undefined;
  buildTab = <BuildTab
    active={buildingEntity}
    playerInfo={props.players.filter(p => p.color == buildingEntity)[0]}
    setPlayerBuilding={setPlayerBuilding}
    addExtraProd={props.addExtraProd}
    builtBuildings={builtBuildings}
    build={build}
    forceType={forceType}
    values={values}
  ></BuildTab>

  let resourceInfos = [];

  function adjustLevel(resName, amt) {
    console.log("adjusting", {resName, amt})
    if (["miners", "people"].includes(resName)) {
      playSound("slide");
    }
    else {
      playSound("slide2");
    }

    props.setLevels(oldLevels => {
      let newLevels = {...oldLevels};
      let levelName;
      levelName = resGuilds[resName];
      if (!levelName) {
        levelName = resName
      }
      if (newLevels[levelName] == 4 && amt > 0 && (!["people", "miners"].includes(resName))) {
        return oldLevels;
      }
      newLevels[levelName] += amt;
      console.log("saving", props.players)

      saveGame({levels: newLevels})
      if (newLevels[levelName] < 0) {
        return oldLevels;
      }
      if (["people", "miners"].includes(resName)) {
        levelName = resName;
        if ({people: 40, miners: 30}[resName] < newLevels[levelName]) {
          return oldLevels;
        }
      }
      return newLevels;
    })
  }

  for (let resName in resTracks) {
    let valueGrid = <>{values[resName].number}</>;
    let yIntervals = 15;
    let xIntervals = 10;
    if (resValues[resName]) {
      let style = {
        transform: "translate(" +
        (-props.levels[resToGuild[resName]] - 0.0) * xIntervals + "dvh, " + (-(props.levels[resTracks[resName]] - 0.1)) * yIntervals + "dvh) ",
      }
      valueGrid = (
        <div className='value-grid' style={style}>
          {resValues[resName].map((row, y) => (
              row.map((value, x) => {
                if (value === 0) {
                  value = <div className='city-builds'></div>
                }
                return <div
                  className={'res-value ' + resName}
                  title={+value || ""}
                  key={`cell-${x}-${y}`}
                  style={{
                    left: (x * xIntervals) + "dvh",
                    top: (y * yIntervals) + "dvh",
                  }}
                >

                  {value}
                </div>
              }
              ))
          )}
        </div>
      );
    }

    let cost = <div className={"resource-cost " + values[resName].color}>{valueGrid}</div>
    let costBg = <></>
    if (values[resName].color == "orange") {
      costBg = <div className='prevent-bg'></div>
    }
    let plusButton = null;
    if (values[resName].color == "red") {

      plusButton = <button
      className={'city-builds-button' + (values[resName].color == "red" ? " pulsing" : "")}
      onClick={() => {
        saveHistory({players: props.players, levels: props.levels});
        adjustLevel(resName, 1)
      }}
      ></button>
    }

    let costWrap = <>
      {costBg}
      {cost}
      {plusButton}
    </>

    let guildDots = [];
    let dotAmt = props.levels[resGuilds[resName]]
    if (dotAmt && resName !== "ore") {
      for (let i = 0; i < dotAmt; ++i) {
        guildDots.push(<div className={'guild-dot dot-' + resToGuild[resName]}></div>);
      }
    }
    let countdownWrap = null;
    let pricePredict = null;
    if (localStorage.predictableMode == "true" && resName !== "people" && resName !== "miners") {
      if (props.levels) {


        let countdowns = [];
        let levels = props.levels
        let trackLevel = levels[resTracks[resName]];
        let guildLevel = levels[resGuilds[resName]];
        let currValue = resValues[resName][trackLevel][guildLevel];
        let countdownT = 0;

        while (resValues[resName][trackLevel + (countdownT)][guildLevel] === currValue) {
          countdownT += 1;
          if (!resValues[resName][trackLevel + (countdownT)]) {
            countdownT = 0;
            break;
          }
        }
        let targetCountdown = Math.min(countdownT, 4)
        countdownWrap = <div className='countdown-wrap'><div className={"countdown " + " countdown-" + targetCountdown}></div></div>

        if (guildLevel <= 4) {
          let possibleMoves = new Set();
          for (let building of buildingList[resGuilds[resName]]) {
            if (builtBuildings.find(built => built.cat == building.cat && built.num == building.num)) {
              continue;
            }
            possibleMoves.add(building.moves.filter(m => m == resTracks[resName]).length);
          }

          let possiblePrices = new Set();
          for (let d of possibleMoves) {
            let newPrice = resValues[resName][trackLevel + d][guildLevel + 1]
            if (newPrice) {
              possiblePrices.add(newPrice);
            }
          }
          possiblePrices = Array.from(possiblePrices)
          if (possiblePrices.length) {
            pricePredict = <div className='price-predict'>
              <div className='price-predict-number'>
                {possiblePrices.sort().join("/")}
              </div>
            </div>

          }
        }

      }
    }
    resourceInfos.push(<div className={"resource-info " + resName + (localStorage.predictableMode == "true" ? " open" : "")}
      key={resName}
    >
      <div className='resource-info-inner-wrap'>
        <div className={"name-countdown-wrap"}>
          <div className={"resource-main-display " + (resName)}></div>
          <div className='guild-dot-wrap'>{guildDots}</div>
        </div>
        {costWrap}
        {countdownWrap}
        {pricePredict}
      </div>
    </div>)

  }

  function saveHistory(data) {
    props.setStateHistory(history => {
      let newHistory = [];
      if (history) {
        newHistory = history.concat([data])
      }
      saveGame({stateHistory: newHistory})
      return newHistory
    });
  }
  let peopleMiners = [];
  for (let resName of ["miners", "people"]) {
    let maxValue = values[resName].number == {people: 40, miners: 30}[resName];
    let minStyle = {}
    if (values[resName].number == 0) {
      minStyle = {visibility: "hidden"}
    }

    let minusButton = <button
      className={'minus-button'}
      style={minStyle}
      onClick={() => {
        saveHistory({players: props.players, levels: props.levels});
        adjustLevel(resName, -1)
      }}
    ></button>

    let incStyle = {}
    if (maxValue) {
      incStyle = {visibility: "hidden"}
    }
    let incButton = <button
    className={'plus-button' + (values[resName].color == "red" ? " pulsing" : "")}
    style={incStyle}
    onClick={() => {
      saveHistory({players: props.players, levels: props.levels});
      adjustLevel(resName, 1)
    }}
    ></button>


    let displayValue = values[resName].number + (maxValue ? "+" : "")
    peopleMiners.push(<><div className={"counters-info " + resName}
      key={resName}
    >
        {minusButton}
        <div className={"resource-name " + (resName)}>
          <div className='res-value' title={displayValue}>{displayValue}</div>
        </div>
        {incButton}
    </div>
  </>)
  }

  let undoButton = null;
  if (props.stateHistory?.length) {
    undoButton = <button className="undo" onClick={() => {
      undo();
    }}>{t("undo")}</button>
  }
  let tutorial = null;
  if (tutorialActive) {
    tutorial = <TutorialOverlay
      setTutorialActive={setTutorialActive}
      setPlayerBuilding={setPlayerBuilding}
    ></TutorialOverlay>
  }
  return <>
  {tutorial}
  <div className={"player-wrap " + (cityBuilds ? "city-builds" : "")}>
    {buildTab}
    {players}
  </div>
    {cityOverlay}
    <div className='people-miners-wrap'>
      {peopleMiners}
      <div className='people-miners-separator'></div>
    </div>
    <div className='resource-divider'></div>
    <div className='resource-wrap'>
      {resourceInfos}
      <div className='resources-separator'></div>
    </div>
    {undoButton}
    <button onClick={() => {
        localStorage.removeItem("kh-tutorial-complete");
        setTutorialActive(true);
        playSound("tap");
      }} className='help-button'></button>;
    {toasts}
  </>;
}

function ToastNotif(props) {
  let [toastState, setToastState] = useState("bottom");
  useEffect(() => {
    setTimeout(() => {setToastState("up")}, 100);
    setTimeout(() => setToastState("out"), 6000);
    playSound("popup")
  }, [])
  return <div className={'toast-notif toast-' + toastState} onClick={() => setToastState("out")}>
    <div className='toast-icon-wrap'>
      {props.toastInfo.icons?.map(iconName => <div className={'toast-icon ' + iconName}></div>)}
    </div><div className='toast-message'>
      {props.toastInfo.message}
    </div>
    <button className='toast-close' onClick={() => setToastState("out")}></button>
    </div>
}

function BuildTab(props) {
  let p = props.playerInfo || {};
  let [category, setCategory] = useState(getFirstPossibleGuild());
  let [mineLevel, setMineLevel] = useState(undefined);
  let [mineProd, setMineProd] = useState(0);
  let [minePeople, setMinePeople] = useState(0);
  let [mineHighlight, setMineHighlight] = useState(false);
  let [selectedBuilding, setSelectedBuilding] = useState(null);
  let {t} = useTranslation()
  function getFirstPossibleGuild() {
    return p.selectedGuildList?.find(cat => props.values[guildToRes[cat]]?.color !== "orange")
  }
  useEffect(() => {
    let guild = getFirstPossibleGuild()
    console.log("setting", guild, p, props.values)
    setCategory(guild)
    setMineProd(0);
    setMinePeople(0);
    setSelectedBuilding(null);
  }, [props.active])

  if (!props.active) {
    return <div className='build-tab-bg inactive'><div className="player-modal"></div></div>
  }


  let buildingCategory = props.forceType || category;
  let buttons = [];
  let production = [];
  let buildingDisplayName = p.playerName || p.color;
  let instruction = <div className='build-instruction'>
    <div className={'player-token ' + p.color}></div>
    <span>{buildingDisplayName}</span>
  </div>;
  let buildings = [];

  function addExtraProd(resName, amt) {
    props.addExtraProd(p.color, resName, amt);
  }

  let playerProduction = getProduction(p)

  buildings = buildingList[buildingCategory];
  for (let cat of p.selectedGuildList) {
    let disabled = false;
    if (props.values[guildToRes[cat]]?.color == "orange") {
      disabled = true;
    }
    buttons.push(<button
      className={"button-cat icon " + cat + (cat === category ? " selected" : "")}
      disabled={disabled}
      onClick={() => {
        setCategory(cat)
        playSound("tap")

        setSelectedBuilding(null)
      }}
      >
      <div className={'guild-button-bgr ' + cat}>
        <div className={'guild-button-img ' + cat}>
        </div>
      </div>
      {disabled ? <div className='guild-disabled'></div> : <></> }</button>
      );
  }
  buttons.push(<div className='cat-select-divider'></div>);
  buttons.push(<button
    className={"button-cat icon public" + ("public" === category ? " selected" : "")}
    onClick={() => {
      setCategory("public")

      setSelectedBuilding(null)
      playSound("tap");
    }}
    ><div className={'guild-button-img public'}></div></button>);
  let productionResources = p.selectedGuildList;
  if (!productionResources.includes("miners")) {
    productionResources = productionResources.concat("miners")
  }
  for (let cat of productionResources) {
    let resName = guildToRes[cat]
    let amt = playerProduction[resName] ?? 0
    let value = props.values[resName]?.number

    production.push(<div class='playertab-production'>
      <div className={"resource-name " + (guildToRes[cat])}>
        <div className='player-prod-number' title={amt}>
          {amt}
        </div>
      </div>
        <div className="resource-prod-number">{amt + "×" + value + "=" + amt * value}</div>
  <div className="resource-explain"></div>
    </div>)
  }
  let buildingButtons = [];
  for (let b in buildings) {
    let building = buildings[b];
    if (props.builtBuildings.find(built => built.cat == building.cat && built.num == building.num)) {
      continue;
    }
    let buildingId = buildingCategory + "-" + building.num
    let overlay = <></>
    let isSelected = buildingId === selectedBuilding

    let overlayText = null;
    if (buildingCategory == "public") {
      overlayText = <div className='building-overlay-text'>{t("public" + building.num)}</div>
    }
    overlay = <div className={'build-select-overlay' + (isSelected ? " active" : "")}>
      <div className='building-thumb'></div>
    </div>
    let customNametyle = {}
    let buildingName = t(buildingId);
    let sizeAdjust = Math.min(0, 45 / buildingName.length - 3)
    let fontSize = (2.5 + sizeAdjust) + "dvh";
    let top = (29 - sizeAdjust * 1.5) + "%"
    customNametyle.fontSize = fontSize;
    customNametyle.top = top;
    let newButton = <button
      className={"button-build " + buildingCategory + " " + buildingCategory + "-" + building.num +
       " " + (isSelected ? "selected" : "")}
      style={{
        /*width: buttonWidth,
        height: buttonWidth*/
      }}
      onClick={(evt) => {
        if (isSelected) {
          props.build(props.playerInfo, building)
        }
        else {
          setSelectedBuilding(buildingId)
          setMinePeople(0);
          setMineProd(0);
          evt.target.scrollIntoView({
            behavior: "smooth"
          })
        }
      }
      }
    >
      <div className='building-name' style={customNametyle}>{buildingCategory === "public" ? null : t(buildingId)}</div>
      {overlayText}
      {overlay}
    </button>;
    buildingButtons.push(newButton)
  }
  let mining = null;
  let barbara = null;

  mining = <div className='mining-tab'>

    <div className={'mine-setup'}>
      <div className='mine-mine-wrap'>
          <button className='minus' disabled={mineProd <= 0} onClick={() => {
            playSound("tap");
            setSelectedBuilding(null);
            setMineProd(p => p-1);
          }}></button>
        <div className='mine-prod-icon-res mine-setup-icon'>

          <div className='res-value' title={mineProd}>{mineProd}</div>
        </div>
        <div className='mine-prod-icon mine-setup-icon'>

          <div className='res-value' title={mineProd}>{mineProd}</div>
        </div>
          <button className='plus' disabled={mineProd >= 3}  onClick={() => {
            playSound("tap");
            setSelectedBuilding(null);
            setMineProd(p => p+1);
          }}></button>
      </div>
      <div className='mine-people-wrap'>
      <button className='minus' disabled={minePeople <= 0} onClick={() => {
        playSound("tap");
        setSelectedBuilding(null);
        setMinePeople(p => p-1);
      }}></button>
        <div className='mine-people-icon mine-setup-icon'>
        <div className='res-value' title={minePeople}>
          {minePeople}
        </div>
        </div>
      <button className='plus' disabled={minePeople >= 3} onClick={() => {
            playSound("tap");
            setSelectedBuilding(null);
            setMinePeople(p => p+1);
          }}></button>
      </div>
    </div>
    <button className='build-mine'
    disabled={minePeople == 0 && mineProd == 0} onClick={() => {
      let moves = [];
      for (let i = 0; i < mineProd; ++i) {
        moves.push(MINER);
      }
      for (let i = 0; i < minePeople; ++i) {
        moves.push(PEOPLE);
      }
      if (moves.length === 0) {
        setMineHighlight(true);
        setTimeout(() => {
          setMineHighlight(false);
        }, 300);
      }
      props.build(props.playerInfo, {prodRes: "ore", prodAmt: mineProd, moves})

    }}></button>
  </div>
  if (!props.builtBuildings.find(b => b.cat == "barbara")) {
    let prodButtons = buildingList.barbara.filter(b => props.playerInfo.selectedGuildList.map(guild => guildToRes[guild]).includes(b.prodRes)).map(
      building => {
        let id = "barbara-" + building.num;
        let selected = selectedBuilding == id

        let confirm = null;
        if (selected) {
          confirm = <div className='barbara-confirm'></div>
        }
        return <button
          className={"button-barbara barbara-" + building.num +
            (selectedBuilding?.includes('barbara') && !selected ? " inactive" : "")}
          style={{
            /*width: buttonWidth,
            height: buttonWidth*/
          }}
          title="+2"
          onClick={() => {


            if (!selected) {
              setMineProd(0);
              setMinePeople(0);
              setSelectedBuilding(id);
              return;
            }
            props.build(props.playerInfo, building)
            playSound("tap");
          }
          }
        >
          +2
          {confirm}
        </button>
      }
    )
    for (let i of [1, 3]) {
      prodButtons.splice(i, 0, <div className='barbara-slash'>/</div>)
    }
    barbara = <div className='barbara-prod'>
      <div className='barbara-inner'>
        <div className='barbara-action'></div>
            {prodButtons}
      </div>
    </div>
  }
  return <div className='build-tab-bg'><div className="player-modal">
    <button className="x-button" onClick={() => props.setPlayerBuilding(undefined)}></button>
    {instruction}
    <div className='build-tab'>
      <div className='shields-offer'>
        {buttons}
      </div>
      <div className={'building-offer ' + category}>
        <div className='offer-margin'></div>
        {buildingButtons}
        <div className='offer-margin'></div>
      </div>
    </div>
    {mining}
    {barbara}
  </div>
  </div>
}

function getProduction(player) {
  let prodAmt = {};
  for (let b of player.buildings) {
    if (b.prodRes) {
      if (!prodAmt[b.prodRes]) {
        prodAmt[b.prodRes] = 0;
      }
      prodAmt[b.prodRes] += b.prodAmt;
    }
  }
  for (let extra in player.extraProduction) {
    if (!prodAmt[extra]) {
      prodAmt[extra] = 0;
    }
    prodAmt[extra] += player.extraProduction[extra];
  }
  return prodAmt;
}

function GamePlayer(props) {
  let p = props.playerInfo;


  let prodAmt = getProduction(p);
  let guilds = [];

  let overviewString = "";

  let prodComponents = [];
  let resouceDetails = [];
  let totalProd = 0;
  for (let resName in prodAmt) {
    let amt = prodAmt[resName];
    let value = props.values[resName].number;
    if (!amt) {
      continue
    }
    totalProd += amt * value;
    prodComponents.push("" + amt + "×" + value);
    resouceDetails.push(<div className={"resource-detail " + resName}>
      <div className={"resource-name " + resName} title={amt}>{amt}</div>
    </div>)
  }
  //overviewString += "" + prodComponents.join(" + ");


  return <div className={'player-info-wrap ' + p.color} playerColor={p.color} >
    <div className='build-info-wrap'>
      <div className={'player-info ' + p.color}
      onClick={() => {
        props.setPlayerBuilding(p.color)
        playSound("tap");
      }}>
        <div className={"player-name"}>{p.playerName}</div>
        <span className={"prod-number"}>{totalProd}</span>
        <div className={"player-overview"}>{overviewString}{resouceDetails}</div>
      </div>
      <button className={'player-build-button ' + p.color} onClick={() => {
        props.setPlayerBuilding(p.color)
        playSound("tap");
      }}></button>
    </div>
  </div>
}

function SelectGame(props) {
  let [setupCard, setSetupCard] = useState(props.selectedSetupCard ?? 5);
  let {t, i18n} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.gameStarted = false;
  }, []);

  let playerAmtButtons = [];
  for (let n = 2; n <= 4; ++n) {
    playerAmtButtons.push(<button
      className={"player-number-button players-" + n + (n == props.playerAmt ? " button-highlight" : "")}
      number={n}
      onClick={(evt) => {
        props.setPlayerAmt(n);
        playSound("tap");
        noSleep.enable();
      }}
      key={"button-" + n}
    >
      <div className={'player-number-image players-' + n}>

      </div>

    </button>)
  }
  let setupCardButtons = [];
  for (let n = 1; n <= 9; ++n) {
    setupCardButtons.push(<div
      className={"carousel-number-button number-" + n + (n == setupCard ? " button-highlight" : "")}
      onClick={(evt) => {
        noSleep.enable();
        evt.target.parentElement.scrollTo({
          left: 0,
          top: evt.target.offsetTop - evt.target.parentElement.offsetHeight / 2,
          behavior: "smooth",
        })
      }}
    >{n}</div>);
  }
  let carousel = <Carousel
    height="1000"
    startNumber={props.selectedSetupCard}
    numberChanged={(n) => {
      setSetupCard(n)
    }}
  >
    {setupCardButtons}
  </Carousel>
  return <>
    <SetupTopBar></SetupTopBar>
    <div className='setup-instructions'>{t("setupCardInstructions")}</div>
    <div className='player-amt-wrap'>
      {playerAmtButtons}
    </div>
    <div>
    <div className={'setup-card card-' + setupCard + " playeramt-" + props.playerAmt}
      onClick={e => {
        /*console.log(e)
        let rect = e.target.getBoundingClientRect();
        let y = e.clientY - rect.top;  //y position within the element.
        let top = y / rect.height;
        let thresholds = [0.36, 0.67]
        props.setPlayerAmt(thresholds.filter(t => t < top).length + 2)
        playSound("tap")*/
      }}
    >

    </div>
    <div className='carousel-card'>
      {carousel}
    </div></div>
    <button className='start-button' onClick={() => {
      props.setSelectedSetupCard(setupCard);
      playSound("tap");
      navigate("/playerColors")
    }}></button>
  </>
}

function SetupTopBar(props) {
  let navigate = useNavigate();
  let location = useLocation();
  let homeButton = null;
  let helpButton = null;
  if (props.homeButton) {
    homeButton = <button onClick={() => {
      navigate("/");
      playSound("tap");
    }} className='home-button'></button>;

    if (window.canPlayTutorial) {

    }
  }
  return     <div className='setup-top'>
    <div className={'setup-logo ' + i18n.language}></div>
    {homeButton}
    {helpButton}
  </div>
}

function SelectPlayers(props) {
  let [playerAmt, setPlayerAmt] = useState(0);
  let [setupCard, setSetupCard] = useState(0);
  let [selectedCards, setSelectedCards] = useState([]);
  let [setupConfirmed, setSetupConfirmed] = useState(false);
  let {t, i18n} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    window.gameStarted = false;
  }, []);

  let playerAmtButtons = [];
  for (let n = 2; n <= 4; ++n) {
    playerAmtButtons.push(<button
      className={"player-number-button" + (n == playerAmt ? " button-highlight" : "")}
      onClick={() => {
        playSound("tap");
        setPlayerAmt(n);
        noSleep.enable();
      }}
      key={"button-" + n}
    >

      {n}
    </button>)
  }
  let setupCardButtons = [];
  for (let n = 1; n <= 9; ++n) {
    setupCardButtons.push(<button
      className={"player-number-button" + (n == setupCard ? " button-highlight" : "")}
      onClick={() => {
        setSetupCard(n);
      }}
    >{n}</button>);
  }
  console.log(props.players)
  let guildSelect = <GuildSelect
    setPlayers={props.setPlayers}
    players={props.players}
    playerAmt={props.playerAmt}
    key="player-form"
    selectedGuilds={props.players.map(p => p.selectedGuildList)}
    selectedColors={props.players.map(p => p.color)}
    selectedSetupNums={props.players.map(p => p.selectedSetupNum)}
    selectedSetupCard={props.selectedSetupCard}
    selectedCards={selectedCards}
    setSelectedCards={setSelectedCards}
    startGame={startGame}
  ></GuildSelect>;

  return <div>

    {guildSelect}
  </div>
  function startGame(players = props.players) {
    console.log("startin", window.gameStarted)
    if (!window.gameStarted) {
      window.gameStarted = true;
      let newPlayers = [];
      for (let i of [...players]) {
        i.buildings = []
        newPlayers.unshift(i);
      }
      props.setPlayers(newPlayers)
      let levels = {
        metallurgists: 0,
        miners: 0,
        scribes: 0,
        people: 0,
        grocers: 0,
        builders: 0,
        innkeepers: 0
      }
      props.setLevels(levels);
      props.setStateHistory([]);
      saveGame({players: newPlayers, levels, stateHistory: []});
      navigate("/game")
    }
  }
}

function MainContent(props) {
  const navigate = useNavigate();
  let [settingsOpen, setSettingsOpen] = useState(false);
  let [players, setPlayers] = useState([])
  let [playerAmt, setPlayerAmt] = useState(3);
  let [selectedSetupCard, setSelectedSetupCard] = useState(5);
  let [stateHistory, setStateHistory] = useState([]);
  var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const location = useLocation();

  const {t, i18n} = useTranslation();
  let [levels, setLevels] = useState({
    metallurgists: 0,
    miners: 0,
    scribes: 0,
    people: 0,
    grocers: 0,
    builders: 0,
    innkeepers: 0
  });
  function addExtraProd(playerColor, resName, amt) {
    setPlayers(oldPlayers => {
      let newPlayers = JSON.parse(JSON.stringify(oldPlayers));
      for (let p of newPlayers) {
        if (p.color === playerColor) {
          if (!p.extraProduction[resName]) {
            p.extraProduction[resName] = 0;
          }
          p.extraProduction[resName] += amt;
        }
      }
      return Object.values(newPlayers)
    })
  }
  useEffect(() => {
    if (localStorage.getItem("kut-saved-game")) {
      let saveData = JSON.parse(localStorage.getItem("kut-saved-game"))
      setLevels(saveData.levels)
      setPlayers(Object.values(saveData.players));
      setStateHistory(saveData.stateHistory)
    }
  }, [])

  let settingScreen = null;
  settingScreen = <Settings setSettingsOpen={setSettingsOpen}></Settings>;
  let settingButton = <button className='settings-button' onClick={() => {
    playSound("tap");
    window.canPlayTutorial = location.pathname.includes("game");
    navigate("/settings");
  }}></button>
  let mainContent = null;

  let loading = <div>{t("loading")}</div>
  let selectGame = <><SelectGame
    players={players}
    selectedSetupCard={selectedSetupCard}
    setSelectedSetupCard={setSelectedSetupCard}
    setPlayerAmt={setPlayerAmt}
    playerAmt={playerAmt}
  >

  </SelectGame>
  {settingButton}
  </>
  let selectPlayers = <SelectPlayers
    players={players}
    playerAmt={playerAmt}
    setPlayers={setPlayers}
    setLevels={setLevels}
    selectedSetupCard={selectedSetupCard}
    setStateHistory={setStateHistory}
  >

  </SelectPlayers>
  let loadButton;
  if (localStorage.getItem("kut-saved-game")) {

    loadButton = <button onClick={() => {
      playSound("tap");
      navigate("game");
      let saveData = JSON.parse(localStorage.getItem("kut-saved-game"))

      console.log({saveData})
      setLevels(saveData.levels)
      setPlayers(saveData.players);
      setStateHistory(saveData.stateHistory);
    }}>{t("loadGame")}</button>
  }
  let mainMenu = <div className='main-menu'>
    <div className={'main-logo ' + i18n.language}></div>
    <button onClick={() => {
      playSound("tap");
      navigate("gameSelect")
    }}><Trans i18nKey="newGame">{t("newGame")}</Trans></button>
    {loadButton}
    {settingButton}
    <a href='https://czechgames.com/en/kutna-hora/' className='cge-logo'></a>
  </div>;

  let game = <><GameTab
    players={Object.values(players) ?? []}
    setPlayers={setPlayers}
    addExtraProd={addExtraProd}
    levels={levels}
    setLevels={setLevels}
    stateHistory={stateHistory}
    setStateHistory={setStateHistory}
  ></GameTab>
  {settingButton}
  </>
  let backButton = <button className='back-button' onClick={() => {
    playSound("tap");
    if (location.pathname.includes("game")) {
      navigate("../")
    }
    else {
      navigate(-1)
    }
  }}></button>
  console.log(window.history)
  if (location.pathname == "/") {
    backButton = null
  }
  return <div className={'content-wrap lang-wrap-' + i18n.language}><Routes>
    <Route path='/' element={mainMenu}></Route>
    <Route path='/gameSelect' element={selectGame}></Route>
    <Route path='/playerColors' element={selectPlayers}></Route>
    <Route path='/game' element={game}></Route>
    <Route path='/settings' element={settingScreen}></Route>
  </Routes>
  {backButton}
  </div>
}


function App(props) {
  return <BrowserRouter><MainContent>

  </MainContent></BrowserRouter>

}

function playerTab(props) {
  return <div htmlClass="player"></div>
}

export default App;
export {SetupTopBar, playSound}