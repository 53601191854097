let strings = {
  translations: {
      "id": "es",
      "newGame": "Nueva partida",
      "loadGame": "Continuar",
      "settings": "Ajustes",
      "loading": "Cargando",
      "language": "Idioma",
      "setupCardInstructions": "Número de jugadores. Carta de preparación.",
      "dragColors": "Arrastra los colores a las losetas de gremio.",
      "tutorial1": "Bienvenido a la aplicación de Kutná Hora. Esta aplicación sustituye a las cartas de población y de mineral, y puede calcular los ingresos de los jugadores. Este tutorial te ofrece una descripción general. Toca en cualquier lugar para continuar.",
      "tutorial2": "En la parte inferior, la aplicación lleva un registro de los precios de los productos.",
      "tutorial3": "Cuando aumente la producción de mineral o la población, díselo a la aplicación tocando el botón\u00a0+.",
      "tutorial4": "El potencial de ingresos de los jugadores aparece en la parte superior de la pantalla.",
      "tutorial5": "Cuando cambie tu producción, toca este botón para abrir una nueva ventana.",
      "tutorial6": "Utiliza esto para informar a la aplicación sobre tu nuevo edificio...",
      "tutorial7": "...o esto para informar a la aplicación sobre tu nueva mina.",
      "tutorial8": "Aquí abajo se puede registrar la producción de la tercera loseta de Santa Bárbara.",
      "tutorial9": "¡Esto es todo lo que necesitas saber! Ahora a pasarlo bien… ¡Buena suerte!",
      "cityBuilds": "Se construye un edificio. Descarta la loseta superior de la pila.",
      "tutorial-skip": "Saltar tutorial",
      "settingsConfirm": "Confirmar",
      "economyInformation": "Información económica",
      "standard": "Modo estándar",
      "open": "Modo abierto",
      "sounds": "Sonidos",
      "undo": "Deshacer",
      "metallurgists-0": "Martillo pilón",
      "metallurgists-1": "Horno",
      "metallurgists-2": "Fundición",
      "metallurgists-3": "Lavaderos de mineral",
      "miners-0": "Molino",
      "miners-1": "Bomba",
      "miners-2": "Pozo de ventilación",
      "miners-3": "Herrería",
      "scribes-0": "Oficina de ensayo",
      "scribes-1": "Mercado de minerales",
      "scribes-2": "Oficina de minería",
      "scribes-3": "Palacio de justicia",
      "builders-0": "Cantería",
      "builders-1": "Aserradero",
      "builders-2": "Horno de cal",
      "builders-3": "Taller de la catedral",
      "grocers-0": "Panadería",
      "grocers-1": "Mercado del pan",
      "grocers-2": "Carnicería",
      "grocers-3": "Pescadería",
      "innkeepers-0": "Taberna",
      "innkeepers-1": "Balneario",
      "innkeepers-2": "Cervecería",
      "innkeepers-3": "Bodega",
      "toastHouse": "¡No olvides el bonificador de tu casa!",
      "toastBarbara": "¡No olvides darle la vuelta a la loseta de Santa Bárbara y aumentar los impuestos!",
      "toastMine": "¡No olvides colocar la loseta gratuita de tecnología minera!",
      "public1": "Un edificio público que aumenta la población en 1.",
      "public2": "Un edificio público que aumenta la población en 2.",
      "econInfoPrice": "Valor actual del producto.",
      "econInfoGuilds": "Número de edificios construidos por el gremio correspondiente.",
      "econInfoCountdown": "Número de aumentos que quedan antes de que cambie el precio.",
      "econInfoPredict": "Posibles valores del producto después de que se construya uno de sus edificios.",
      "openEconDisclaimer": "Este modo no afectará a los valores de los productos, pero tendrás más información sobre cómo van a cambiar los valores. Este modo es para jugadores a los que les gusta tener un poco más de control en el juego y quieren información más completa sobre los precios. Sin embargo, no es la forma de juego prevista originalmente."
  }
}

export default strings;