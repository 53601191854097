let strings = {
  translations: {
	"id": "fr",
	"newGame": "Nouvelle partie",
	"loadGame": "Reprendre",
	"settings": "Réglages",
	"loading": "Chargement",
	"language": "Langue",
	"setupCardInstructions": "Nombre de joueurs et carte Mise en place des guildes",
	"dragColors": "Choisissez votre couleur et faites glisser le pion sur les guildes de votre choix.",
	"tutorial1": "Bienvenue sur l'appli du jeu Kutná Hora ! Elle remplace les présentoirs des cartes Population et Minerai et permet de calculer vos revenus. Cliquez n'importe où pour suivre le tutoriel qui vous présentera ces fonctionnalités.",
	"tutorial2": "En bas, vous avez le prix des différentes ressources.",
	"tutorial3": "Dès que la population ou la production de minerai augmente, indiquez-le en cliquant sur le +.",
	"tutorial4": "En haut, l'application indique le revenu potentiel de chaque joueur.",
	"tutorial5": "Dès que votre production évolue, cliquez sur le + pour ouvrir une nouvelle fenêtre.",
	"tutorial6": "Si vous construisez un nouveau bâtiment, indiquez-le ici.",
	"tutorial7": "Et si vous construisez une nouvelle mine, indiquez-le ici.",
	"tutorial8": "Ici, vous pourrez indiquer la production liée à la troisième tuile Sainte-Barbe.",
	"tutorial9": "Eh voilà, vous savez tout ! Bonne chance et amusez-vous bien !",
	"cityBuilds": "La ville construit un bâtiment. Défaussez la tuile du dessus de la pile.",
	"tutorial-skip": "Passer le tutoriel.",
	"settingsConfirm": "Confirmer",
	"economyInformation": "Infos sur l'économie",
	"standard": "Mode standard",
	"open": "Mode détaillé",
	"sounds": "Son",
	"undo": "Annuler",
	"metallurgists-0": "Concasserie",
	"metallurgists-1": "Fourneau",
	"metallurgists-2": "Fonderie",
	"metallurgists-3": "Laverie de minerai",
	"miners-0": "Moulin à cheval",
	"miners-1": "Pompe",
	"miners-2": "Puits d'aération",
	"miners-3": "Forge",
	"scribes-0": "Bureau d'analyse",
	"scribes-1": "Marché aux minerais",
	"scribes-2": "Office des mines",
	"scribes-3": "Tribunal",
	"builders-0": "Atelier des maçons",
	"builders-1": "Scierie",
	"builders-2": "Four à chaux",
	"builders-3": "Atelier des bâtisseurs",
	"grocers-0": "Boulangerie",
	"grocers-1": "Marché au pain",
	"grocers-2": "Boucherie",
	"grocers-3": "Marché aux poissons",
	"innkeepers-0": "Taverne",
	"innkeepers-1": "Bains publics",
	"innkeepers-2": "Brasserie",
	"innkeepers-3": "Entrepôt à vin",
	"toastHouse": "N'oubliez pas d'appliquer l'effet Maison !",
	"toastBarbara": "N'oubliez pas de retourner la tuile Sainte-Barbe et d'augmenter les impôts !",
	"toastMine": "N'oubliez pas de placer la tuile Technologie minière gratuite !",
	"public1": "Bâtiment public : Population +1.",
	"public2": "Bâtiment public : Population +2.",
	"econInfoPrice": "Prix actuel de la ressource.",
	"econInfoGuilds": "Nombre de bâtiments construits par la guilde correspondante.",
	"econInfoCountdown": "Augmentations restantes avant le prochain changement de prix.",
	"econInfoPredict": "Prix potentiels de cette ressource quand l'un de ses bâtiments sera construit.",
	"openEconDisclaimer": "Ce mode n'a pas d'impact sur le prix des ressources. Vous aurez simplement accès à plus d'informations sur les fluctuations des prix. Ce mode s'adresse à celles et ceux qui préfèrent avoir du contrôle sur leurs parties en ayant plus d'informations. Gardez toutefois en tête que le jeu, à la base, n'est pas pensé pour que les joueurs aient accès à autant de données."
}
}

export default strings;