import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import './settings.css';
import './App.css';
import './index.css';
import { useState } from "react";
import { SetupTopBar, playSound } from "./App";
import { useNavigate } from "react-router-dom";

function Settings(props) {
  let navigate = useNavigate();
  function Header(props) {
    return <div className="header">
      <div className="arrow arrow-left"></div>
      <div className="header-text">{props.title}</div>
      <div className="arrow arrow-right"></div>
    </div>
  }
  const { t, i18n } = useTranslation();
  console.log(localStorage.getItem("predictableMode"))
  const [predictableMode, setPredictableMode] = useState(localStorage.getItem("predictableMode") == "true");
  const [soundOn, setSoundOn] = useState(localStorage.getItem("soundOn") == "true");
  let buttons = [];
  function _setPredictableMode(value) {
    setPredictableMode(value);
    localStorage.setItem("predictableMode", value)
  }
  function _setSoundOn(value) {
    setSoundOn(value);
    localStorage.setItem("soundOn", value)
  }
  for (let language of ["cs", "en", "es", "ja", "de", "fr", "ko"]) {

    buttons.push(<button onClick={() => {
      i18n.changeLanguage(language)
      playSound("tap")
    }}
    className={"lang-button lang-" + language + (i18n.language.includes(language) ? " active" : "")}></button>)
  }
  let economyDescription = <div className="economy-info standard-info">
    {t("econInfoPrice")}
    <div className="info-image active"></div>
    {t("econInfoGuilds")}
  </div>
  if (predictableMode) {
    economyDescription = <>
    <div className="econ-disclaimer">{t("openEconDisclaimer")}</div>
    <div className="economy-info open-info">
      {t("econInfoCountdown")}
      <div className={"info-image active"}></div>
      {t("econInfoPredict")}
    </div>
    </>
  }
  return <><SetupTopBar settings={true} homeButton={true}></SetupTopBar><div className="settings">

    <Header title={t("language")}></Header>
    <div className="lang-buttons">
      {buttons}
    </div>
    <Header title={t("sounds")}></Header>
    <button
        className={"sound-button sound-on " + (soundOn ? "active" : "")}
        onClick={() => {
          _setSoundOn(true)
          playSound("tap")
        }}
        ></button>
    <button
        className={"sound-button sound-off " + (soundOn ? "" : "active")}
        onClick={() => {
          _setSoundOn(false)
        }}
        ></button>
    <Header title={t("economyInformation")}></Header>
    <div className="economy-wrap">
      <div className="economy-info">
        <button
        className={"economy-select" + (predictableMode ? "" : " active")}
        onClick={() => {
          _setPredictableMode(false)
          playSound("tap")
        }}
        >{t("standard")}</button>

      </div>
      <div className="economy-info">

      <button
        className={"economy-select" + (predictableMode ? " active" : "")}
        onClick={() => {
          _setPredictableMode(true)
          playSound("tap")
        }}
        >{t("open")}</button>

      </div>
    </div>
      {economyDescription}
  </div>
  </>
}

export {Settings}